import { FetcherWithComponents } from '@remix-run/react';

type NavigationState = FetcherWithComponents<unknown>['state'];
type NavigationLike = unknown & { state: NavigationState; formData?: FormData };

export function isLoading(navigationLike: NavigationLike, intent?: string) {
  if (!intent) {
    return navigationLike.state === 'submitting';
  }
  return navigationLike.state === 'submitting' && navigationLike.formData?.get('intent') === intent;
}
